<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="61.76mm" height="34.98mm" viewBox="0 0 175.07 99.17">
        <g id="Capa_2" data-name="Capa 2">
            <g id="Capa_1-2" data-name="Capa 1">
                <path class="cls-1" d="M83.46.18a33,33,0,0,1,10,.46A50.13,50.13,0,0,1,106,4.71c3.83,2,6.71,4.65,7.57,8.36a47.14,47.14,0,0,0,1.67,15.64,43.7,43.7,0,0,0-12.1.25h0C89.66,31.22,79.57,39.9,71.41,49.55,66,56,61.35,62.86,57.05,68.62S48.77,79,44.94,80.91A19.11,19.11,0,0,1,43,81.7H43v.06a28.34,28.34,0,0,1-13,1c-4.48-.65-8.46-2.3-10.64-4.24a27.46,27.46,0,0,0,5.53-4.7c5.21-5.66,9.58-13.85,14-22.74C47.7,33.3,56.74,12.77,71.4,4.18h0a26.3,26.3,0,0,1,6.39-2.84h.08A30.83,30.83,0,0,1,82,.43h0L83.46.26Z"/>
                <path class="cls-1" d="M68.77,3.1C54,12.69,45.33,32.91,36.82,50c-4.4,8.85-8.74,16.89-13.63,22.21C19,76.84,14.5,79.41,9,79c5-3.61,8.6-9.25,11.81-15.8,3.73-7.62,6.94-16.52,11-25.17C39,22.91,48.78,8.61,68.77,3.1Z"/>
                <path class="cls-1" d="M128.41,7.5a4,4,0,0,1,1.41,1.76,22.79,22.79,0,0,1,1.41,4.45c.83,3.62,1.45,8.39,2.22,13.46,1.46,9.63,3.48,20.29,8.9,27-3.18,2.41-6.56,3.84-10,5.53-9.57-15.25-17.38-32.3-16.45-45.85a19,19,0,0,1,3.71-.89c1.72-.3,3.65-.74,4.86-2.29l.12-.13h.08a5.65,5.65,0,0,0,.46-.44c.39-.36.94-.83,1.5-1.28a15.21,15.21,0,0,1,1.59-1.13,2,2,0,0,0,.28-.13Z"/>
                <path class="cls-1" d="M132.09,8.16l.4,1.3c1.15-.35,4.87-1.13,8.27-1.74,1.7-.29,3.35-.57,4.61-.74l1.53-.18c.21-.07.32-.07.42,0h.16l.18.06c.12,0,0-.07.15.1a3.4,3.4,0,0,1,.39,1.25,34.22,34.22,0,0,1,.27,5.5c.07,4.55.07,10.39,1.85,15.43.74,2.06,2.6,5.57,4.16,9.06a43.62,43.62,0,0,1,1.91,4.88,7.85,7.85,0,0,1,.4,2.85c-.08,0-.15.17-.39.36a10.64,10.64,0,0,1-1.46.86A36.89,36.89,0,0,1,150.33,49a48.49,48.49,0,0,1-4.86,1.38c-.7.15-1.31.25-1.77.3s-.8,0-.66.07l-.15.4c-3.92-6.12-5.75-15.57-7.07-24.28-.77-5-1.38-9.82-2.25-13.63A24.35,24.35,0,0,0,132,8.34c0-.06,0-.06-.09-.07Z"/>
                <path class="cls-1" d="M158.56,9.53a5.66,5.66,0,0,1,1.55,0,1.06,1.06,0,0,1,.64.29c.09.1.21.28.16.79-.42,6,1.81,14.92,9.43,19.86.74.48.87.81.88.93a1.43,1.43,0,0,1-.43.77A16.92,16.92,0,0,1,165.62,35a76.92,76.92,0,0,1-8.89,2.72,4.11,4.11,0,0,1-.25-.52c-1.6-3.58-3.5-7.24-4.1-8.9-1.63-4.48-1.68-10.1-1.72-14.66a11.64,11.64,0,0,0-.07-1.58,39.28,39.28,0,0,1,5.85-2.16,18.11,18.11,0,0,1,2.07-.39Z"/>
                <path class="cls-1" d="M167.43,12.43a4,4,0,0,1,1.68.15c.52.21.84.49,1,1.55A7.54,7.54,0,0,0,174,20a3.66,3.66,0,0,0,1,.31l-6.33,5.19,1,1.24-.27.21a20.38,20.38,0,0,1-6.27-13.24,16.11,16.11,0,0,1,3.49-1.19,3.84,3.84,0,0,1,.7-.09Z"/>
                <path class="cls-1" d="M105,31a42.67,42.67,0,0,1,10.86.13c2.75,9.07,7.52,18.45,12.91,27.3-.61-.12-1.27-.21-2-.3a71.66,71.66,0,0,0-7.19-.47,91.84,91.84,0,0,0-15.93,1c-11.17,1.93-18.49,9.73-26.92,15.87-7.7,5.61-16.23,10-29.81,8C51.1,80,54.87,75.41,58.9,70c4.34-5.83,8.94-12.65,14.27-19C81.5,41.2,91.46,32.65,105,31Z"/>
                <path class="cls-1" d="M105.77,60.62a96.19,96.19,0,0,1,13.79-.69,68.45,68.45,0,0,1,7,.46,13.73,13.73,0,0,1,3.82.9l.15-.2c.15.22.28.46.43.69l.14.21c-1.27,3.77-4.46,7.75-9.19,11.6-5.68,4.62-13.31,9-21.44,12.81C84.15,94,65.7,99.12,57,99.17c-23.27-.06-40.73-4.31-57-19.91,2.25.6,3.88,1,5.72,1.59l.08.06h.09a17.16,17.16,0,0,0,11.44-1.23C20,82.46,24.59,84.29,29.74,85a30.59,30.59,0,0,0,13.51-.92c16.17,3.43,26.43-1.54,35-7.75s15.65-13.7,26-15.46c.53-.1,1.1-.18,1.69-.25Z"/>
            </g>
        </g>
    </svg>

</template>

<script>
    export default {
        name: 'RecursoCaracola',
    }
</script>

<style scoped>
    .cls-1 {
        fill: #14a1bc;
        fill-opacity: 0.9;
    }
</style>
