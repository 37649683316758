<template>
    <div :id="id" class="backgrund-image-placement" v-bind:style="{'background-image': 'url('+ src +')'}"></div>
</template>

<script>
    // @ is an alias to /src

    export default {
        name: 'FotoCuadrada',

        props: ['src'],

        data() {
            return {
                id: null
            };
        },

        components: {},

        mounted(){
            var ro = new ResizeObserver(entries => {
                for (let entry of entries) {
                    const cr = entry.contentRect;
                    entry.target.style.height = cr.width + 'px';
                }
            });

            ro.observe(document.getElementById(this.id));

        },

        created() {
            this.id = Math.floor(Math.random() * Date.now());
        }
    }
</script>
