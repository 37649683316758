<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="171.42mm" height="59.44mm" viewBox="0 0 485.91 168.48">
        <g id="Capa_2" data-name="Capa 2">
            <g id="Capa_1-2" data-name="Capa 1">
                <g>
                    <path class="cls-1" d="M21.72,139.07c-7.26,0-13.73,6-15,13-1.37,7.37,3.44,12.84,10.23,12.84A15.68,15.68,0,0,0,32.2,152.08c1.48-8-3.58-13-10.48-13M7.89,125.14h4L8.84,141.77H9a18.07,18.07,0,0,1,13.44-6.33c10.3,0,15.43,8.06,13.87,16.46a20.71,20.71,0,0,1-20,16.58c-3.29,0-8.54-1.15-11.1-6.27H5.05l-1,5.52H0Z"/>
                    <path class="cls-1" d="M69.57,149.83c.11-7.42-5.31-10.76-10.32-10.76A15.66,15.66,0,0,0,45,149.83Zm-25.25,3.63a10,10,0,0,0,10.09,11.4,15,15,0,0,0,12.66-7.43h4.26c-3.35,6.27-10.28,11-17.53,11-8.81,0-15.08-7.14-13.35-16.46,1.65-8.92,10.23-16.41,19.41-16.58,6.9.06,11,4.15,12.56,8.06a15.37,15.37,0,0,1,.57,10Z"/>
                    <path class="cls-1" d="M92.64,164.86c6.79,0,13.62-5.47,15-12.84,1.28-6.91-3-13-10.2-13a15.79,15.79,0,0,0-15.31,13c-1.3,7,2.82,12.78,10.53,12.78m15.87,2.87h-4l1-5.52h-.12A17.72,17.72,0,0,1,92,168.48c-9.9,0-15.53-7.54-13.85-16.58a20.77,20.77,0,0,1,20-16.46c3.4,0,8.72,1.1,11.09,6.33h.11l1-5.52h4Z"/>
                    <path class="cls-1" d="M148.74,158.47c-2.74,3.91-8.07,10-17.51,10-9.84,0-15.5-7.65-13.87-16.46,1.68-9.1,10.61-16.58,20-16.58,5.53,0,12.1,2.77,13.75,10.31h-4.43c-1.65-4.78-5.9-6.68-9.87-6.68-6.16,0-13.86,4.6-15.39,12.84-1.31,7.07,3.07,12.94,10.61,12.94a15,15,0,0,0,12.29-6.38Z"/>
                    <path class="cls-1" d="M160.8,125.14h4L162,140.28h.11a13.9,13.9,0,0,1,10.57-4.84c3.86,0,6.9,1,9.24,3.86,1.94,2.3,2.82,5.93,1.86,11.11l-3.21,17.32h-4l3.21-17.32c.76-4.09,0-7-1.39-8.64a8.42,8.42,0,0,0-6.35-2.7,12,12,0,0,0-7.35,2.7,14,14,0,0,0-4.54,8.64l-3.21,17.32h-4Z"/>
                    <path class="cls-1" d="M210,136.25h4l-.84,4.55h.11c2.72-4.44,6.42-5.13,9.86-5.36l-.79,4.26a11.83,11.83,0,0,0-7.37,3.11,12.54,12.54,0,0,0-3.39,6.5l-3.41,18.42h-4Z"/>
                    <path class="cls-1" d="M251.73,149.83c.11-7.42-5.32-10.76-10.33-10.76a15.65,15.65,0,0,0-14.25,10.76Zm-25.25,3.63a10,10,0,0,0,10.09,11.4,15,15,0,0,0,12.66-7.43h4.25c-3.34,6.27-10.28,11-17.53,11-8.8,0-15.08-7.14-13.35-16.46,1.65-8.92,10.23-16.41,19.42-16.58,6.9.06,11,4.15,12.55,8.06a15.37,15.37,0,0,1,.57,10Z"/>
                    <path class="cls-1" d="M262.06,158.41c-1,6,3,6.45,4.44,6.45a6.6,6.6,0,0,0,6.92-5.65c.8-4.31-2.93-5.29-5.11-6.27-3-1.09-7.82-2.82-6.73-8.75a10.78,10.78,0,0,1,10.61-8.75c4.72,0,8,3.63,7,8.58h-4c.43-3.86-2-5-3.8-5a5.86,5.86,0,0,0-5.65,4.72c-.62,3.34,2.51,4.49,4.67,5.24,3.46,1.21,8.38,3.22,7.18,9.72a11.33,11.33,0,0,1-11.71,9.73c-4.37,0-9.28-3-7.8-10.07Z"/>
                    <polygon class="cls-1" points="289.84 139.87 283.22 139.87 283.9 136.25 290.52 136.25 292.58 125.14 296.6 125.14 294.54 136.25 302.09 136.25 301.41 139.87 293.87 139.87 288.71 167.73 284.68 167.73 289.84 139.87"/>
                    <polygon class="cls-1" points="473.66 139.87 467.04 139.87 467.71 136.25 474.34 136.25 476.4 125.14 480.42 125.14 478.36 136.25 485.91 136.25 485.23 139.87 477.69 139.87 472.53 167.73 468.5 167.73 473.66 139.87"/>
                    <path class="cls-1" d="M316.64,164.86c6.8,0,13.62-5.47,15-12.84,1.28-6.91-3-13-10.21-13a15.78,15.78,0,0,0-15.3,13c-1.3,7,2.81,12.78,10.52,12.78m15.88,2.87h-4l1-5.52h-.11A17.76,17.76,0,0,1,316,168.48c-9.9,0-15.52-7.54-13.85-16.58a20.78,20.78,0,0,1,20-16.46c3.39,0,8.71,1.1,11.08,6.33h.12l1-5.52h4Z"/>
                    <path class="cls-1" d="M367.23,167.73h-3.8l.91-4.89h-.12a13.24,13.24,0,0,1-11.11,5.64c-6.22,0-8.89-3-9.95-4.72-1.73-2.76-1.51-6.79-.89-10.13l3.23-17.38h4l-3.21,17.32c-.73,4-.35,6.57.71,8.29,1.73,2.48,4.57,3,6.76,3a10.6,10.6,0,0,0,7.14-2.54c2.19-1.84,4-3.91,5-9.44L369,136.25h4Z"/>
                    <path class="cls-1" d="M380.67,136.25h4l-.85,4.55H384c2.72-4.44,6.42-5.13,9.86-5.36L393,139.7a11.83,11.83,0,0,0-7.37,3.11,12.54,12.54,0,0,0-3.39,6.5l-3.41,18.42h-4Z"/>
                    <path class="cls-1" d="M408.29,164.86c6.8,0,13.62-5.47,15-12.84,1.28-6.91-3-13-10.21-13a15.78,15.78,0,0,0-15.3,13c-1.3,7,2.81,12.78,10.52,12.78m15.88,2.87h-4l1-5.52h-.11a17.74,17.74,0,0,1-13.43,6.27c-9.9,0-15.52-7.54-13.85-16.58a20.78,20.78,0,0,1,20-16.46c3.39,0,8.71,1.1,11.08,6.33H425l1-5.52h4Z"/>
                    <path class="cls-1" d="M437.32,136.25h4l-.75,4h.12a13.84,13.84,0,0,1,10.56-4.84c3.86,0,6.9,1,9.25,3.86,1.93,2.3,2.81,5.93,1.85,11.11l-3.21,17.32h-4l3.21-17.32c.76-4.09,0-7-1.39-8.64a8.43,8.43,0,0,0-6.35-2.7,12,12,0,0,0-7.35,2.7,14,14,0,0,0-4.54,8.64l-3.21,17.32h-4Z"/>
                </g>
                <g>
                    <path class="cls-1" d="M.06,26.84H9.85v9.92c3.77-8,11.88-11.6,20.41-11.6A31.33,31.33,0,0,1,55,36.76c.7,1,1.26,2,2,2.94.42-1,1-1.82,1.53-2.94,5.31-7.4,15.24-11.6,24.19-11.6,8.24,0,16.21,4.06,23.76,11.6,6.15,6.15,7.13,14,7.13,22.93V103.3H103.22V60.67c0-5.59-.41-11.74-3.77-16.5a20.67,20.67,0,0,0-17.33-9.08A21.2,21.2,0,0,0,65.2,43.75C61.85,48.51,62,55.08,62,60.67V103.3H51.64V59.55c0-5.73-.27-10.21-3.63-15.24a21.28,21.28,0,0,0-17.19-9.22A20.81,20.81,0,0,0,14,43.19c-3.49,5-3.63,10.49-3.63,16.36V103.3H.06Z"/>
                    <path class="cls-1" d="M138.58,65.42c0,16.77,14.53,29.77,30.89,29.77,15.94,0,29.63-13.7,29.63-29.63,0-16.92-12.86-30.47-29.91-30.47a30.46,30.46,0,0,0-30.61,30.33M209,103.3H199.1V88.9c-5.87,10.77-17.61,16.22-29.63,16.22-11.46,0-23.9-5-33.69-16.22-2.79-3.07-7.55-12.72-7.55-24.32,0-10.2,5.18-20.69,8-23.2,11.6-12.45,22.37-16.22,33-16.22,12,0,24.18,5.31,29.91,16.22V26.84H209Z"/>
                    <rect class="cls-1" x="226.63" width="10.35" height="103.3"/>
                    <path class="cls-1" d="M254.59,26.84h10.34V103.3H254.59Zm0-26.84h10.34V17.61H254.59Z"/>
                    <path class="cls-1" d="M300.29,44.31c-6,6-8.11,13-8.11,21.39,0,17,13.84,29.49,30.62,29.49A30.13,30.13,0,0,0,352.85,65c0-17.33-13.7-29.91-30.75-29.91a30.46,30.46,0,0,0-21.81,9.22M282.54,0h10.34V41.1c5-10.63,17.61-15.94,28.94-15.94,22.22,0,41.37,16.92,41.37,39.56,0,11-4.89,20.83-9.08,25.44-10.63,12-20.13,15-31.31,15s-24.46-5-30.47-15V103.3h-9.79Z"/>
                    <path class="cls-1" d="M445.24,103.3h-9.79V91.42c-5,9.78-14.67,13.7-25.3,13.7-9.78,0-18.17-3.5-26.83-13.7-4.62-4.89-5.46-14.12-5.46-24.18V26.84h10.35v40.4c0,5.87.28,11.74,3.35,16.91,4.19,7.27,11.6,11,20,11a23.1,23.1,0,0,0,18.73-9.36c3.91-5.45,4.61-12,4.61-18.59V26.84h10.35Z"/>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'TextoMalibu',
    }
</script>

<style scoped>
    .cls-1 {
        fill: #13a1bc;
    }
</style>
