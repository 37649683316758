<template>

    <div style="position: fixed; width: 100%; z-index: -1; height: 100vh" class="double-gradient">

    </div>

    <div class="home teal-text text-darken-2">

        <div class="container d-none d-md-block mb-5 mt-4">
            <video
                    autoplay loop muted playsinline
                    poster="https://sethotels.ams3.cdn.digitaloceanspaces.com/malibu/vlcsnap-2023-09-21-09h59m28s002.webp"
                    style="width: 100%"
                    class="border-radius-15 overflow-hidden">
                <source src="https://sethotels.ams3.cdn.digitaloceanspaces.com/malibu/video_desktop_5.mp4"
                        type="video/mp4"/>
            </video>
        </div>

        <div class="container d-block d-md-none mb-5 mt-4">
            <video
                    autoplay loop muted playsinline
                    style="width: 100%"
                    poster="https://sethotels.ams3.cdn.digitaloceanspaces.com/malibu/vlcsnap-2023-09-21-09h59m28s002.webp"
                    class="border-radius-15 overflow-hidden">
                <source src="https://sethotels.ams3.cdn.digitaloceanspaces.com/malibu/video_cabecera_web_comprimido.mp4"
                        type="video/mp4"/>
            </video>
        </div>


        <div class="white-text container position-relative mb-5">
            <div class="border-radius-15 color-azul">
                <div class="text-center" style="padding: 50px 30px">
                    <div style="max-width: 500px" class="center-align font-size-18 color-azul mb-5">
                        {{i18n.home.malibu_text}}
                    </div>
                    <OlasBlancas class="d-none d-md-block m-auto" style="height: 60px; width: fit-content"/>
                    <OlasBlancas class="d-block d-md-none" style="width: 100%;"/>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="border-radius-15 overflow-hidden">
                <img src="@/assets/img/foto_1.webp" style="width: 100%">
            </div>
        </div>

        <div id="carta" class="container position-relative" style="padding: 80px 0px">
            <RecursoConcha class="position-absolute d-none d-md-block" style="height: 80px; bottom: 80px; left: 10%;"/>

            <div class="center-align text-center">
                <h5 class="font-size-48 mb-4 stay-outside-font color-azul-text">
                    {{i18n.home.come_bebe}}
                </h5>
                <a href="https://cartas.sethotels.net/malibu" type="button" target="_blank"
                   class="button white-text color-azul text-decoration-none">
                    {{i18n.buttons.carta}}
                </a>
            </div>
        </div>

        <div class="container center-align mb-5" style="width: 100%">
            <PhotoCarousel
                    :images="[
                        'https://sethotels.ams3.cdn.digitaloceanspaces.com/malibu/DSC09773.webp',
                        'https://sethotels.ams3.cdn.digitaloceanspaces.com/malibu/IMG_5249.webp',
                        'https://sethotels.ams3.cdn.digitaloceanspaces.com/malibu/IMG_5849.webp',
                        'https://sethotels.ams3.cdn.digitaloceanspaces.com/malibu/IMG_5273.webp',
                        'https://sethotels.ams3.cdn.digitaloceanspaces.com/malibu/IMG_5854.webp',
                        'https://sethotels.ams3.cdn.digitaloceanspaces.com/malibu/IMG_5965.webp',
                        'https://sethotels.ams3.cdn.digitaloceanspaces.com/malibu/IMG_5991.webp',
                        'https://sethotels.ams3.cdn.digitaloceanspaces.com/malibu/IMG_6379_1.webp',
                        'https://sethotels.ams3.cdn.digitaloceanspaces.com/malibu/IMG_6451.webp',
                        'https://sethotels.ams3.cdn.digitaloceanspaces.com/malibu/j.webp',
                        'https://sethotels.ams3.cdn.digitaloceanspaces.com/malibu/DSC09653.webp',
                        'https://sethotels.ams3.cdn.digitaloceanspaces.com/malibu/DSC09694.webp',
                    ]"/>
        </div>

        <div class="container mb-5">
            <div class="border-radius-15 overflow-hidden">
                <img src="@/assets/img/foto_2.webp" style="width: 100%">
            </div>
        </div>

        <!-- Frase -->
        <div class="container mb-5 position-relative">
            <RecursoCaracola style="height: 70px; bottom: 0px; right: 0px" class="position-absolute d-none d-md-block"/>

            <div class="center-align text-center color-azul-text font-size-22">
                <div class="mb-3">
                    <div v-html="i18n.home.instagram" class="mb-4"></div>
                    <a href="" class="ghotic-font-bold color-azul-text text-decoration-none">@malibu_menorca</a>
                </div>
            </div>
        </div>

        <!-- 3 Fotos -->
        <div class="container mb-5 d-none d-md-block">
            <div class="row">
                <div class="col-lg-4">
                    <FotoCuadrada src="https://sethotels.ams3.cdn.digitaloceanspaces.com/malibu/IMG_5854.webp"/>
                </div>
                <div class="col-lg-4">
                    <FotoCuadrada src="https://sethotels.ams3.cdn.digitaloceanspaces.com/malibu/IMG_6451.webp"/>
                </div>
                <div class="col-lg-4">
                    <FotoCuadrada src="https://sethotels.ams3.cdn.digitaloceanspaces.com/malibu/IMG_5965.webp"/>
                </div>
            </div>
        </div>

        <div class="container center-align mb-5 d-block d-md-none" style="width: 100%">
            <FotoCuadrada class="border-radius-15"
                          src="https://sethotels.ams3.cdn.digitaloceanspaces.com/malibu/IMG_5854.webp"/>
        </div>

        <div id="geolocal" class="container mb-5">
            <div class="center-align text-center color-azul-text font-size-22">
                <div class="mb-3">
                    Urbanización Santo Tomas, S/N, 07749
                    <br/>
                    Santo Tomás, Menorca, España
                </div>

                <div class="mb-3">
                    Tel. +34 971 38 57 89
                </div>

                <div class="mb-3">
                    {{i18n.home.horario}} 11:00 – 00:00
                </div>
            </div>
        </div>

        <div class="container">
            <div>
                <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d321.66205029533745!2d4.038189136903933!3d39.914934362069005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12be1fd7446eb30f%3A0xb7a91f3669183b2c!2sRestaurante%20Malib%C3%BA!5e0!3m2!1ses!2ses!4v1694077291339!5m2!1ses!"
                        width="100%"
                        height="450"
                        style="border:0;"
                        class="border-radius-15 overflow-hidden"
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>

        <div class="center-align" style="padding: 90px 0px">
            <MalibuLogo style="height: 200px"/>
        </div>
    </div>

</template>

<script>

    // @ is an alias to /src

    import MalibuLogo from "@/svg/MalibuLogo";
    import RecursoConcha from "@/svg/RecursoConcha";
    import PhotoCarousel from "@/components/PhotoCarousel";
    import OlasBlancas from "@/svg/OlasBlancas";
    import RecursoCaracola from "@/svg/RecursoCaracola";
    import FotoCuadrada from "@/components/FotoCuadrada";

    export default {
        name: 'HomeView',

        components: {
            FotoCuadrada,
            RecursoCaracola,
            OlasBlancas,
            PhotoCarousel,
            RecursoConcha,
            MalibuLogo,
        },
    }

</script>

<style scoped>
    .double-gradient {
        display: grid;
        place-items: center;
        height: 200px;
        width: 200px;
        background: linear-gradient(rgba(255, 255, 255, 1) 0%, rgba(251, 251, 251, 0.1) 100%),
        linear-gradient(90deg, rgba(0, 172, 200, 0.3), rgba(141, 90, 205, 0.05));
    }
</style>