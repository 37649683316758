<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="41.57mm" height="38.21mm" viewBox="0 0 117.83 108.31">
        <g id="Capa_2" data-name="Capa 2">
            <g id="Capa_1-2" data-name="Capa 1">
                <path class="cls-1"
                      d="M50.32,108.31l-1.62-7.16c1-5.31-2.32-4.23-4.64-7.58l-10.5-4.24-12.37,1c-18.7.9-12.83-6.49-17.12-11.7C.57,74.94,48,91.07,47.43,89.18,46.32,85.26,6.49,77.81,2.89,72.82c-3.36-.6-3.68-11.61-1.52-13.25,3.41-2.59,95.09,56,1.92-4.77C.57,51.43,5.07,42.88,6.87,40.35c-4-5.94,47.6,45.32,48,44S12.09,42,8.66,37c.52-5.42,2.1-11.49,7.41-15,6.55,11,39.59,60.22,41.11,58.68s-40-59.14-38.88-60.9c2.09-3.28,10.69-9.34,12-8.36l30.6,67.76L33.41,9.46C38.21,5.7,41,3.4,44.86,3.66L64.06,76,47.9,2.92c3.19-.51,5-2.51,10-1.06l9.72,74.62L60.64.94c2.66-.85,6-1.83,8.93.57,3.18-.22-.58,70.13,1.25,72.56L72.89,1.56c1.93-3.4,8.54,0,10.71,1.79-3.07,56.51-7.21,46-8.64,71.46L86.27,4C88.89,5.39,91.89,5.51,93.86,9L78.13,75.23,95.87,11.08c-.2,0,8.35,1.95,5.61,7.32L81.3,75.81l22.38-55.36c3.88,1.5,6.27,4.56,6.41,10l-25,47.13,26.35-46.15c3.68,3.78,3.64,8.56,3.42,11.44L87.51,78.22l28-33.19c1.81-.31,4.33,12.08-.34,13.82C113.93,60,92.79,76.62,92.79,76.62l-1.27,7.24s-.63,4.93.61,6.41S96,95.18,96,95.18s-13.44,8-15.76,8.15S65,107.09,63.56,106.72s-7.22.58-7.22.58Z"/>
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'RecursoConcha',
    }
</script>

<style scoped>
    .cls-1 {
        fill: #14a1bc;
    }
</style>
