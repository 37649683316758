<template>
    <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 672.67 672.67">
        <circle class="cls-1" cx="350.23" cy="350.37" r="333.87" transform="translate(-159.06 336.23) rotate(-45)"/>
        <path class="cls-2"
              d="M107.54,307.52h10.69v10.84c4.12-8.7,13-12.67,22.27-12.67a34.16,34.16,0,0,1,27,12.67c.77,1.06,1.38,2.13,2.14,3.2.46-1.07,1.07-2,1.68-3.2,5.79-8.09,16.63-12.67,26.39-12.67,9,0,17.7,4.43,25.94,12.67,6.71,6.71,7.78,15.25,7.78,25V391H220.14V344.44c0-6.1-.46-12.81-4.12-18a22.57,22.57,0,0,0-18.92-9.91A23.13,23.13,0,0,0,178.64,326c-3.66,5.19-3.51,12.36-3.51,18.46V391H163.84V343.22c0-6.25-.3-11.13-4-16.63a23.21,23.21,0,0,0-18.76-10.06,22.7,22.7,0,0,0-18.31,8.84c-3.81,5.49-4,11.44-4,17.85V391h-11.3Z"
              transform="translate(-13.89 -14.03)"/>
        <path class="cls-2"
              d="M258.72,349.63c0,18.31,15.87,32.5,33.72,32.5,17.39,0,32.34-14.95,32.34-32.35,0-18.46-14-33.26-32.65-33.26a33.25,33.25,0,0,0-33.41,33.11M335.62,391H324.78V375.26C318.37,387,305.56,393,292.44,393c-12.51,0-26.09-5.49-36.77-17.7-3-3.36-8.24-13.88-8.24-26.54,0-11.14,5.65-22.58,8.7-25.33,12.66-13.58,24.41-17.7,36-17.7,13.13,0,26.4,5.8,32.65,17.7V307.52h10.84Z"
              transform="translate(-13.89 -14.03)"/>
        <rect class="cls-2" x="340.94" y="264.2" width="11.29" height="112.75"/>
        <path class="cls-2" d="M385.34,307.52h11.29V391H385.34Zm0-29.29h11.29v19.22H385.34Z"
              transform="translate(-13.89 -14.03)"/>
        <path class="cls-2"
              d="M435.23,326.59c-6.57,6.56-8.86,14.19-8.86,23.35,0,18.61,15.11,32.19,33.42,32.19a32.89,32.89,0,0,0,32.8-33c0-18.91-14.95-32.65-33.57-32.65a33.24,33.24,0,0,0-23.79,10.07m-19.38-48.36h11.29v44.85c5.49-11.59,19.22-17.39,31.58-17.39,24.25,0,45.16,18.46,45.16,43.18,0,12.05-5.34,22.73-9.92,27.76C482.36,389.76,472,393,459.79,393s-26.7-5.49-33.26-16.33V391H415.85Z"
              transform="translate(-13.89 -14.03)"/>
        <path class="cls-2"
              d="M593.42,391H582.74V378c-5.49,10.68-16,14.95-27.61,14.95-10.68,0-19.83-3.81-29.29-14.95-5-5.34-6-15.41-6-26.4V307.52h11.29v44.09c0,6.41.3,12.82,3.66,18.46,4.58,7.94,12.66,12.06,21.82,12.06A25.23,25.23,0,0,0,577.1,371.9c4.27-5.94,5-13.11,5-20.29V307.52h11.29Z"
              transform="translate(-13.89 -14.03)"/>
        <path class="cls-2"
              d="M88.88,226.72c-8.54,0-16.16,7.11-17.67,15.25-1.61,8.68,4.05,15.12,12,15.12a18.47,18.47,0,0,0,18-15c1.74-9.43-4.21-15.32-12.35-15.32M72.6,210.31h4.75L73.72,229.9h.13a21.25,21.25,0,0,1,15.82-7.45c12.14,0,18.17,9.49,16.34,19.38a24.38,24.38,0,0,1-23.55,19.53c-3.86,0-10-1.36-13.07-7.39h-.13l-1.21,6.51H63.31Z"
              transform="translate(-13.89 -14.03)"/>
        <path class="cls-2"
              d="M145.25,239.39c.12-8.74-6.26-12.67-12.16-12.67a18.44,18.44,0,0,0-16.79,12.67Zm-29.74,4.27a11.81,11.81,0,0,0,11.88,13.43,17.65,17.65,0,0,0,14.91-8.75h5c-3.95,7.39-12.11,13-20.65,13-10.37,0-17.76-8.41-15.73-19.39,2-10.51,12.06-19.32,22.87-19.53,8.12.07,13,4.89,14.78,9.5a18.12,18.12,0,0,1,.68,11.72Z"
              transform="translate(-13.89 -14.03)"/>
        <path class="cls-2"
              d="M172.41,257.09c8,0,16-6.44,17.65-15.12,1.51-8.13-3.48-15.25-12-15.25A18.58,18.58,0,0,0,160,242c-1.53,8.27,3.32,15,12.4,15m18.69,3.39h-4.74l1.2-6.51h-.13a20.91,20.91,0,0,1-15.81,7.39c-11.66,0-18.28-8.88-16.31-19.53,1.83-9.89,11.39-19.38,23.52-19.38,4,0,10.27,1.28,13.06,7.45H192l1.21-6.5H198Z"
              transform="translate(-13.89 -14.03)"/>
        <path class="cls-2"
              d="M238.48,249.56c-3.22,4.61-9.5,11.8-20.62,11.8-11.59,0-18.26-9-16.34-19.39,2-10.71,12.5-19.52,23.55-19.52,6.51,0,14.24,3.25,16.19,12.13H236c-1.94-5.62-6.95-7.86-11.63-7.86-7.25,0-16.32,5.42-18.12,15.12-1.54,8.33,3.62,15.24,12.5,15.24a17.68,17.68,0,0,0,14.47-7.52Z"
              transform="translate(-13.89 -14.03)"/>
        <path class="cls-2"
              d="M252.68,210.31h4.74l-3.3,17.83h.14a16.29,16.29,0,0,1,12.44-5.69c4.54,0,8.12,1.15,10.89,4.54,2.27,2.71,3.31,7,2.18,13.08L276,260.48h-4.74L275,240.07c.89-4.81,0-8.2-1.64-10.17a10,10,0,0,0-7.48-3.18,14.14,14.14,0,0,0-8.66,3.18c-2.4,2-4.52,5.36-5.34,10.17l-3.78,20.41h-4.75Z"
              transform="translate(-13.89 -14.03)"/>
        <path class="cls-2"
              d="M310.59,223.39h4.75l-1,5.36h.13c3.21-5.22,7.56-6,11.61-6.3l-.93,5a13.91,13.91,0,0,0-8.68,3.66,14.77,14.77,0,0,0-4,7.66l-4,21.69h-4.75Z"
              transform="translate(-13.89 -14.03)"/>
        <path class="cls-2"
              d="M359.77,239.39c.13-8.74-6.26-12.67-12.16-12.67a18.44,18.44,0,0,0-16.79,12.67ZM330,243.66a11.81,11.81,0,0,0,11.88,13.43,17.65,17.65,0,0,0,14.91-8.75h5c-3.95,7.39-12.11,13-20.65,13-10.37,0-17.76-8.41-15.73-19.39,2-10.51,12.06-19.32,22.87-19.53,8.13.07,13,4.89,14.79,9.5a18.12,18.12,0,0,1,.67,11.72Z"
              transform="translate(-13.89 -14.03)"/>
        <path class="cls-2"
              d="M371.94,249.49c-1.17,7.05,3.54,7.6,5.23,7.6a7.77,7.77,0,0,0,8.15-6.65c.94-5.08-3.46-6.23-6-7.39-3.56-1.28-9.21-3.32-7.92-10.3a12.67,12.67,0,0,1,12.48-10.3c5.56,0,9.38,4.27,8.23,10.09h-4.74c.5-4.53-2.38-5.82-4.48-5.82a6.91,6.91,0,0,0-6.66,5.56c-.73,3.93,3,5.28,5.5,6.16,4.08,1.43,9.87,3.8,8.45,11.46-1.11,6.37-5.91,11.46-13.78,11.46-5.15,0-10.94-3.53-9.19-11.87Z"
              transform="translate(-13.89 -14.03)"/>
        <polygon class="cls-2"
                 points="390.77 213.63 382.97 213.63 383.76 209.36 391.56 209.36 393.99 196.28 398.73 196.28 396.31 209.36 405.19 209.36 404.39 213.63 395.51 213.63 389.44 246.44 384.69 246.44 390.77 213.63"/>
        <polygon class="cls-2"
                 points="607.26 213.63 599.46 213.63 600.25 209.36 608.05 209.36 610.47 196.28 615.22 196.28 612.79 209.36 621.67 209.36 620.88 213.63 612 213.63 605.92 246.44 601.17 246.44 607.26 213.63"/>
        <path class="cls-2"
              d="M436.22,257.09c8,0,16-6.44,17.65-15.12,1.51-8.13-3.48-15.25-12-15.25a18.57,18.57,0,0,0-18,15.32c-1.54,8.27,3.31,15,12.39,15m18.7,3.39h-4.75l1.2-6.51h-.13a20.91,20.91,0,0,1-15.81,7.39c-11.66,0-18.28-8.88-16.31-19.53,1.83-9.89,11.39-19.38,23.52-19.38,4,0,10.27,1.28,13.06,7.45h.14l1.2-6.5h4.75Z"
              transform="translate(-13.89 -14.03)"/>
        <path class="cls-2"
              d="M495.8,260.48h-4.47l1.06-5.77h-.13a15.63,15.63,0,0,1-13.1,6.65c-7.32,0-10.46-3.53-11.71-5.56-2-3.26-1.77-8-1-11.93l3.79-20.47h4.74l-3.78,20.4c-.86,4.68-.41,7.73.84,9.76,2,2.92,5.38,3.53,8,3.53a12.49,12.49,0,0,0,8.41-3c2.57-2.17,4.72-4.61,5.93-11.11l3.62-19.59h4.75Z"
              transform="translate(-13.89 -14.03)"/>
        <path class="cls-2"
              d="M511.63,223.39h4.74l-1,5.36h.13c3.21-5.22,7.56-6,11.61-6.3l-.93,5a13.91,13.91,0,0,0-8.68,3.66,14.77,14.77,0,0,0-4,7.66l-4,21.69h-4.75Z"
              transform="translate(-13.89 -14.03)"/>
        <path class="cls-2"
              d="M544.16,257.09c8,0,16-6.44,17.65-15.12,1.5-8.13-3.48-15.25-12-15.25a18.58,18.58,0,0,0-18,15.32c-1.53,8.27,3.32,15,12.4,15m18.69,3.39h-4.74l1.2-6.51h-.13a20.91,20.91,0,0,1-15.81,7.39c-11.66,0-18.29-8.88-16.31-19.53,1.83-9.89,11.38-19.38,23.52-19.38,4,0,10.27,1.28,13,7.45h.14l1.21-6.5h4.74Z"
              transform="translate(-13.89 -14.03)"/>
        <path class="cls-2"
              d="M578.34,223.39h4.75l-.89,4.75h.14a16.33,16.33,0,0,1,12.44-5.69c4.55,0,8.13,1.15,10.89,4.54,2.28,2.71,3.32,7,2.19,13.08l-3.78,20.4h-4.75l3.78-20.4c.89-4.81,0-8.2-1.64-10.17a9.91,9.91,0,0,0-7.48-3.18,14.05,14.05,0,0,0-8.65,3.18c-2.4,2-4.52,5.36-5.35,10.17l-3.78,20.4h-4.74Z"
              transform="translate(-13.89 -14.03)"/>
        <path class="cls-2"
              d="M634.24,414.73c-12-3.48-25.58-7.42-52.43-7.42s-40.44,3.94-52.43,7.42c-11.11,3.22-20.7,6-40.13,6s-29-2.78-40.13-6c-12-3.48-25.58-7.42-52.43-7.42s-40.45,3.94-52.44,7.42c-11.1,3.22-20.7,6-40.12,6s-29-2.78-40.12-6c-12-3.48-25.59-7.42-52.43-7.42s-40.45,3.94-52.43,7.42c-11.11,3.22-20.7,6-40.13,6s-29-2.78-40.12-6C67,411.28,53.49,407.37,27,407.32v4.13c19.05.05,28.58,2.8,39.58,6,12,3.48,25.58,7.42,52.43,7.42s40.44-3.94,52.43-7.42c11.11-3.22,20.7-6,40.13-6s29,2.78,40.12,6c12,3.48,25.58,7.42,52.43,7.42s40.44-3.94,52.43-7.42c11.1-3.22,20.7-6,40.13-6s29,2.78,40.13,6c12,3.48,25.58,7.42,52.43,7.42s40.44-3.94,52.43-7.42c11.11-3.22,20.7-6,40.13-6s29,2.78,40.13,6c11.92,3.46,25.45,7.37,52,7.41v-4.12c-19.14-.05-28.69-2.8-39.72-6"
              transform="translate(-13.89 -14.03)"/>
        <path class="cls-2"
              d="M628.16,441.57c-11.74-3.48-25-7.42-51.31-7.42s-39.57,3.94-51.31,7.42c-10.87,3.22-20.25,6-39.27,6s-28.4-2.78-39.27-6c-11.73-3.48-25-7.42-51.31-7.42s-39.57,3.94-51.3,7.42c-10.87,3.22-20.26,6-39.27,6s-28.39-2.78-39.26-6c-11.73-3.48-25-7.42-51.31-7.42s-39.57,3.94-51.3,7.42c-10.87,3.22-20.25,6-39.26,6s-28.4-2.78-39.27-6c-11.65-3.45-24.87-7.36-50.78-7.41v4.13c18.65,0,28,2.8,38.74,6,11.73,3.48,25,7.41,51.31,7.41s39.57-3.93,51.3-7.41c10.87-3.22,20.25-6,39.26-6s28.4,2.78,39.27,6c11.73,3.48,25,7.41,51.3,7.41s39.57-3.93,51.31-7.41c10.87-3.22,20.26-6,39.26-6s28.41,2.78,39.28,6c11.73,3.48,25,7.41,51.3,7.41s39.58-3.93,51.31-7.41c10.87-3.22,20.26-6,39.27-6s28.4,2.78,39.27,6c11.67,3.46,24.9,7.37,50.91,7.41v-4.13c-18.74,0-28.08-2.8-38.87-6"
              transform="translate(-13.89 -14.03)"/>
        <path class="cls-2"
              d="M622.43,468.4c-11.49-3.47-24.52-7.41-50.25-7.41s-38.75,3.94-50.25,7.41c-10.64,3.22-19.84,6-38.46,6s-27.81-2.78-38.46-6c-11.49-3.47-24.51-7.41-50.25-7.41s-38.76,3.94-50.25,7.41c-10.64,3.22-19.84,6-38.45,6s-27.81-2.78-38.46-6c-11.49-3.47-24.51-7.41-50.24-7.41s-38.76,3.94-50.25,7.41c-10.64,3.22-19.84,6-38.45,6s-27.82-2.78-38.46-6C78.79,465,65.85,461.05,40.47,461v4.13c18.26.06,27.39,2.81,37.94,6,11.49,3.47,24.51,7.41,50.25,7.41s38.75-3.94,50.24-7.41c10.65-3.22,19.84-6,38.46-6s27.81,2.78,38.45,6c11.49,3.47,24.51,7.41,50.25,7.41s38.75-3.94,50.24-7.41c10.65-3.22,19.85-6,38.46-6s27.82,2.78,38.46,6c11.49,3.47,24.52,7.41,50.25,7.41s38.76-3.94,50.26-7.41c10.64-3.22,19.83-6,38.45-6s27.82,2.78,38.46,6c11.43,3.46,24.39,7.37,49.86,7.41V474.4c-18.35,0-27.5-2.8-38.07-6"
              transform="translate(-13.89 -14.03)"/>
        <text class="cls-3" transform="translate(150 533.07)">
            <tspan class="cls-4 ghotic-font-normal">by</tspan>
            <tspan x="50" y="0" class="ghotic-font-medium">SETH SANTO TOMAS</tspan>
        </text>
    </svg>
</template>

<script>
    export default {
        name: 'EsPinsLogo',
    }
</script>

<style scoped>
    .cls-1 {
        fill: none;
        stroke: #13a1bc;
        stroke-miterlimit: 10;
        stroke-width: 4.93px;
    }

    .cls-2, .cls-3 {
        fill: #13a1bc;
    }

    .cls-3 {
        font-size: 34px;
        font-family: Barlow-Bold,
        Barlow;
        font-weight: 700;
    }

    .cls-4 {
        letter-spacing: -0.02em;
    }

    .cls-5 {
        letter-spacing: -0.03em;
    }

    .cls-6 {
        letter-spacing: -0.03em;
    }

    .cls-7 {
        letter-spacing: -0.04em;
    }
</style>
