<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="228.23mm" height="25.12mm" viewBox="0 0 646.95 71.22">
        <g id="Capa_2" data-name="Capa 2">
            <g id="Capa_1-2" data-name="Capa 1">
                <path class="cls-1" d="M607.23,7.41C595.24,3.94,581.65,0,554.8,0s-40.44,3.94-52.43,7.41c-11.11,3.22-20.7,6-40.13,6s-29-2.78-40.13-6C410.12,3.94,396.53,0,369.68,0s-40.44,3.94-52.43,7.41c-11.11,3.22-20.7,6-40.13,6s-29-2.78-40.12-6C225,3.94,211.42,0,184.57,0s-40.44,3.94-52.43,7.41c-11.11,3.22-20.7,6-40.12,6s-29-2.78-40.13-6C40,4,26.48.06,0,0V4.13c19.05.06,28.58,2.81,39.58,6,12,3.47,25.58,7.41,52.44,7.41s40.43-3.94,52.42-7.41c11.11-3.22,20.7-6,40.13-6s29,2.78,40.12,6c12,3.47,25.58,7.41,52.43,7.41s40.44-3.94,52.43-7.41c11.11-3.22,20.7-6,40.13-6s29,2.78,40.13,6c12,3.47,25.58,7.41,52.43,7.41s40.44-3.94,52.44-7.41c11.1-3.22,20.69-6,40.12-6s29,2.78,40.13,6c11.93,3.46,25.45,7.37,52,7.41V13.41c-19.14,0-28.69-2.8-39.72-6"/>
                <path class="cls-1" d="M601.15,34.25c-11.74-3.47-25-7.41-51.31-7.41s-39.57,3.94-51.31,7.41c-10.86,3.22-20.25,6-39.26,6s-28.41-2.78-39.27-6c-11.74-3.47-25-7.41-51.31-7.41s-39.58,3.94-51.31,7.41c-10.87,3.22-20.26,6-39.27,6s-28.39-2.78-39.26-6c-11.73-3.47-25-7.41-51.31-7.41s-39.57,3.94-51.3,7.41c-10.87,3.22-20.25,6-39.26,6s-28.4-2.78-39.27-6C46.06,30.8,32.85,26.9,6.93,26.84V31c18.65.05,28,2.81,38.74,6,11.73,3.48,25,7.42,51.31,7.42s39.57-3.94,51.3-7.41c10.87-3.22,20.26-6,39.26-6s28.4,2.78,39.27,6c11.73,3.47,25,7.41,51.3,7.41s39.57-3.94,51.31-7.42c10.87-3.22,20.26-6,39.27-6s28.4,2.78,39.27,6c11.73,3.47,25,7.41,51.31,7.41s39.57-3.94,51.31-7.42c10.86-3.22,20.25-6,39.26-6s28.4,2.77,39.27,6c11.67,3.46,24.9,7.37,50.91,7.42V40.25c-18.74,0-28.08-2.8-38.87-6"/>
                <path class="cls-1" d="M595.42,61.09c-11.49-3.48-24.51-7.42-50.24-7.42s-38.76,3.94-50.25,7.42c-10.65,3.22-19.84,6-38.46,6s-27.82-2.78-38.47-6c-11.49-3.48-24.51-7.42-50.25-7.42S329,57.61,317.51,61.09c-10.65,3.22-19.84,6-38.46,6s-27.81-2.78-38.45-6c-11.5-3.48-24.52-7.42-50.25-7.42s-38.76,3.94-50.25,7.42c-10.64,3.22-19.84,6-38.45,6s-27.82-2.78-38.46-6c-11.41-3.45-24.35-7.36-49.73-7.41v4.13c18.26,0,27.4,2.8,37.94,6,11.49,3.48,24.51,7.42,50.25,7.42s38.75-3.94,50.24-7.42c10.65-3.22,19.84-6,38.46-6s27.81,2.78,38.45,6c11.49,3.48,24.52,7.42,50.25,7.42s38.75-3.94,50.25-7.42c10.64-3.22,19.84-6,38.45-6s27.82,2.78,38.46,6c11.5,3.48,24.52,7.42,50.26,7.42s38.76-3.94,50.25-7.42c10.64-3.22,19.83-6,38.46-6s27.81,2.78,38.45,6c11.44,3.46,24.39,7.37,49.86,7.41V67.09c-18.35,0-27.5-2.8-38.07-6"/>
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'OlasBlancas',
    }
</script>

<style scoped>
    .cls-1 {
        fill: #ffffff;
    }
</style>
